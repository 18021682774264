import { AbstractControl, FormBuilder } from "@angular/forms";
import { inject } from "@angular/core";
import { NotificationService } from "../services/notification.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { FirebaseService } from "../services/firebase.service";
import { ModuleEnum } from './enums/module.enum';
import { COMPANY_MODULES } from "../settings/module.settings";


export class ComponentBase {
  protected formBuilder: FormBuilder;
  protected notificationService: NotificationService;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected location: Location;
  protected firebaseService: FirebaseService;
  protected modules: ModuleEnum[];
  public translateService: TranslateService;
  public formatDate: string;

  constructor() {
    this.formBuilder = inject(FormBuilder);
    this.notificationService = inject(NotificationService);
    this.router = inject(Router);
    this.activatedRoute = inject(ActivatedRoute);
    this.location = inject(Location);
    this.translateService = inject(TranslateService);
    this.firebaseService = inject(FirebaseService);
    this.modules = COMPANY_MODULES;

    this.formatDate = 'dd/MM/yyyy';

    /// Remove modules for COM
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    if (userData?.companyId == "-NoD5KwdjRoU06qfYC7t" || userData?.company == "comunidad-de-madrid") this.modules = [ModuleEnum.interviews, ModuleEnum.feedback, ModuleEnum.conversations];

  }

  protected compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
    let auxA = a === undefined ? '' : a;
    let auxB = b === undefined ? '' : b;
    return (auxA < auxB ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public backUrl() {
    this.location.back();
  }

  public toUpperCase(str) {
    str = str.replace(/_/g, " ")
    return str.toLowerCase().split(' ').map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    }).join(' ');
  }

  protected toLowerCaseControl(control: AbstractControl) {
    return control.setValue(control.value.toLowerCase());
  }
}
