import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLettersPipe } from './first-letters.pipe';
import { JoinPipe } from './join.pipe';
import { ForInPipe } from './for-in.pipe';
import { FilterPipe } from './filter.pipe';
import { SlicePipe } from './slice.pipe';
import { SplitPipe } from './split.pipe';
import { RangePipe } from './range.pipe';
import { AssessmentPipe } from './assessment.pipe';
import { TimerFormatPipe } from './timer-format.pipe';
import { SortPipe } from './sort.pipe';
import { SafePipe } from './safe.pipe';
import { FilterFormPipe } from './filter-form.pipe';
import { DictionaryPipe } from './dictionary.pipe';
import { SnakeCaseToTitlePipe } from './snake-case-to-title.pipe';
import { FindPipe } from './find.pipe';
import { UserProgressPipe } from './user-progress.pipe';
import { RolePipe } from './role.pipe';
import { CountPipe } from './count.pipe';

@NgModule({
  declarations: [
    CustomPipesModule.PIPES,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomPipesModule.PIPES,
  ],
  providers: [
    CustomPipesModule.PIPES
  ]
})
export class CustomPipesModule {
  public static PIPES = [
    FirstLettersPipe,
    JoinPipe,
    ForInPipe,
    FilterPipe,
    SlicePipe,
    SplitPipe,
    RangePipe,
    AssessmentPipe,
    TimerFormatPipe,
    SortPipe,
    SafePipe,
    FilterFormPipe,
    DictionaryPipe,
    SnakeCaseToTitlePipe,
    FindPipe,
    UserProgressPipe,
    RolePipe,
    CountPipe,
  ];
}
