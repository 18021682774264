import { Pipe, PipeTransform } from '@angular/core';
import { ModuleEnum } from '../../utils/enums/module.enum';
import { SIMULATION_PROGRESS_SETTINGS } from '../../modules/admin/user/settings/simulation-progress.settings';
import { GenericFunctions } from '../../utils/generic-functions';
import { UserGradeEnum } from '../../utils/enums/user-grade.enum';
import { UserJourneysService } from '../../services/user-journeys.service';

@Pipe({
  name: 'avatarSimulationProgress'
})
export class AvatarSimulationProgressPipe implements PipeTransform {
  
  constructor(private userJourneyService: UserJourneysService) {
  }
  
  async transform(simulations: any[], module: ModuleEnum, company: string, user: string, simulationProgressSettings?: any): Promise<any> {
    if (!module) {
      return;
    }
    const userGrade: UserGradeEnum = await this.userJourneyService.getUserGrade(company, user, module);
    if (GenericFunctions.isNullOrUndefined(userGrade)) {
      return;
    }
    const settings = simulationProgressSettings || SIMULATION_PROGRESS_SETTINGS;
    const userSettings = settings.avatar.find(x => x.userGrade === userGrade);
    const levels = {};
    let requiredSimulations = 0;
    let totalSimulations = 0;
    for (const levelSettings of userSettings.config) {
      const completedSimulations = simulations.filter(x => x.level === levelSettings.level
        && this.isCompleteSimulation(x.attempts, settings.score)).length;
      const required = levelSettings.value;
      requiredSimulations += required || 0;
      totalSimulations += completedSimulations > required ? required : completedSimulations;
      levels[levelSettings.level] = {
        level: levelSettings.level,
        required,
        completedSimulations,
        label: `${completedSimulations}/${required}`,
        percentage: Math.round((completedSimulations < required ? completedSimulations / required : 1) * 100)
      };
    }
    const percent = requiredSimulations ? (totalSimulations / requiredSimulations) * 100 : 0;
    return {
      levels,
      percent,
    };
  }
  
  private isCompleteSimulation = (attempts: any, score: number): boolean => {
    if (!attempts) {
      return false;
    }
    return Object.keys(attempts).some(key => key.startsWith('-') && attempts[key].scoring >= score);
  };
}
