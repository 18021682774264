import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(values: any[], filter: any[], filterProperty?: string): any[] {
    if (!values) {
      return [];
    }
    if(!filterProperty) {
      return values.filter(x => filter.find(y => y === x))
    }
    return values.filter(x => filter.find(y => y === x[filterProperty]))
  }

}
