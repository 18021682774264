import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  isSideNavCollapsed: boolean = true;
  screenWidth: number = 0;
  public isLoggedUser: boolean;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private translate: TranslateService,
    private router: Router,
    public authService: AuthService
    ) {

    this.translate.setDefaultLang('en');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        document.getElementById('topNavbar')?.scrollIntoView();
      }
    })
  }

  ngOnInit() {
    this.isLoggedUser = !!localStorage.getItem('userInfo');
    this.translate.use('es');
    this.setFavicon();
  }

  private setFavicon() {
    if (environment.name != "production") {
      let links = this.doc.getElementsByTagName('link');
      Array.from(links).forEach(function (element) {
        if (element.rel == 'icon') element.remove();
      });

      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('type', 'image/png');
      link.setAttribute('href', `assets/icon/favicon${environment.name == "development" ? '_dev' : environment.name == "staging" ? '_staging' : ''}.png`);
      this.doc.head.appendChild(link);
    }
  }

  isLargeScreen() {
    return true;//window.innerWidth >= 1200;
  }

  onToggleSideNav(data: SideNavToggle) {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
}
