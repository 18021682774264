<div class="p-5 h-full bg-white max-h-[calc(95vh)]">
  <loading-component *ngIf="isLoading"></loading-component>

  <div *ngIf="!isLoading">
    <div class="grid grid-cols-1 gap-2 p-2 w-full text-gray-800">

      <div class="col-span-2 flex flex-row pb-4">
        <h1 class="font-semibold text-lg leading-4 w-full" *ngIf="this.userCategory === 'employee'" translate>Add users</h1>
        <h1 class="font-semibold text-lg leading-4 w-full" *ngIf="this.userCategory === 'demo'" translate>Add demo users</h1>
        <h1 class="font-semibold text-lg leading-4 w-full" *ngIf="this.userCategory === 'manager'" translate>Add managers</h1>
        <div class="w-10 flex flex-row-reverse">
          <mat-icon class="w-20 h-20 cursor-pointer hover:opacity-70" (click)="closeDialog()">close</mat-icon>
        </div>
      </div>
      <mat-button-toggle-group *ngIf="['admin'] | role" [value]="this.userCategory" (change)="this.userCategoryHandler($event.value)">
        <mat-button-toggle value="employee" class="w-full" [class.bg-yellow-900]="this.userCategory === 'employee'">{{'Employee'| translate}}</mat-button-toggle>
        <mat-button-toggle value="manager" class="w-full" [class.bg-yellow-900]="this.userCategory === 'manager'">{{'Manager'| translate}}</mat-button-toggle>
        <mat-button-toggle value="demo" class="w-full" [class.bg-yellow-900]="this.userCategory === 'demo'">{{'Demo'| translate}}</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="col-span-2 flex">
        <form action="" class="flex flex-col w-full" [formGroup]="emailForm">

          <span class="block text-sm text-gray-800 rounded-md p-2 mb-6 inline-block" translate>
            Add a list of email addresses separated by a comma, semicolon or add each email address in a new line.
          </span>
          <div class="block text-sm text-gray-800 rounded-md p-2 inline-block" *ngIf="this.userCategory === 'demo'">
            <mat-icon aria-hidden="false" fontIcon="warning" class="align-text-bottom mr-2"></mat-icon>
            <span class="inline" translate>The users you add will be configured as DEMO users with limited access of 15-days to the platform.</span>&nbsp;
            <span class="inline" translate>These users will receive an email from SimSkills, instructing them on how to log in with an automatically generated password.</span>
          </div>

          <mat-form-field appearance="fill" class="mt-4">
            <mat-label class="pl-2" translate>Language</mat-label>
            <mat-select formControlName="lang">
              <mat-option value="es">Español</mat-option>
              <mat-option value="en">English</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="(this.userCategory === 'employee' || this.userCategory === 'manager')" [hidden]="isManager" appearance="fill">
            <mat-label class="pl-2" translate>Company</mat-label>
            <mat-select formControlName="company">
              <mat-option *ngFor="let company of companies" [value]="company.value.code" (click)="this.selectCompany(company)">{{company.label}}</mat-option>
            </mat-select>
            <mat-error *ngIf="this.emailForm.controls['company']?.errors | error : 'required'" translate>Field required</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="this.userCategory === 'demo'" appearance="fill">
            <mat-label class="pl-2" translate>Company</mat-label>
            <input matInput formControlName="company" [matAutocomplete]="companyAutocomplete" #demoCompany>
            <mat-autocomplete autoActiveFirstOption #companyAutocomplete="matAutocomplete">
              <mat-option *ngFor="let company of this.filteredCompanies | async" [value]="company.label" (click)="this.selectCompany(company)">
                {{company.label}}
              </mat-option>
            </mat-autocomplete>
            <mat-error nglFormValidator [customErrors]="[{error: 'companyName', message: 'Only letters should be used' | translate }]"
                       [formControl]="$any(this.emailForm.get('company'))" [inputReference]="demoCompany"></mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="this.isEnabledDepartments" appearance="fill">
            <mat-label class="pl-2" translate>Employment office</mat-label>
            <mat-select formControlName="department">
              <mat-option *ngFor="let department of this.departments" [value]="department.id.toString()">{{department.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="this.emailForm.controls['department']?.errors | error : 'required'" translate>Field required</mat-error>
          </mat-form-field>

          <custom-input *ngIf="['admin'] | role"  [label]="'Email domain' | translate" textPrefix="@" formControlName="emailDomain" floatLabel="always"></custom-input>

          <mat-form-field class="w-full">
            <mat-label class="pl-2" translate>Module</mat-label>
            <mat-select multiple formControlName="module">
              <mat-option *ngFor="let module of this.modules" [value]="module">{{module | dictionary: 'module'}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-full">
            <mat-label translate>Email</mat-label>
            <textarea matInput formControlName="emailInput" placeholder="email1@simskills.io, email2@simskills.io, etc."
                      cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10" (blur)="this.toLowerCaseControl(this.emailForm.get('emailInput'))"
            ></textarea>
            <mat-error *ngIf="this.emailForm.controls['emailInput']?.errors | error : 'required'" translate>{{'Email is required'| translate}}</mat-error>
            <mat-error *ngIf="this.emailForm.controls['emailInput']?.errors | error : 'maxLength'">
              {{'Have' | translate}} {{this.emailForm.get('emails').value?.length}}
              {{'emails but, the max email limit is:' | translate}} {{this.emailLength}}
            </mat-error>
            <mat-error class="" *ngIf="this.emailForm.controls['emailInput']?.hasError('emailLimit')">
              {{ 'Override the max email limit of' | translate }} {{this.emailLimit}}
            </mat-error>
          </mat-form-field>
          <div *ngIf="this.emailForm.controls['emailInput']?.errors | error : 'emailDomains'" class="pl-[13px] text-[10px] mt-[-3.5em] w-70">emailLimit
            <mat-error class="" *ngIf="this.emailForm.controls['emailInput']?.hasError('emailDomains')">
              <div><span translate>All emails should be corporate emails ending with</span> @{{this.emailDomainControl.value}}</div>
              <div translate>Users not permitted:</div>
              <ng-container *ngFor="let email  of (this.emailForm.controls['emailInput']?.errors | error : 'emailDomains')?.invalidEmails">
                <li *ngIf="email">{{email}}</li>
              </ng-container>
            </mat-error>
          </div>

        </form>
      </div>

      <div class="col-span-2 flex flex-col">
        <custom-button class="w-full" [disabled]="this.emailForm.invalid || this.emailForm.controls['emailInput']?.disabled" (click)="this.addUser()">{{'Add users' | translate}}</custom-button>
        <div translate class="block text-sm text-gray-800 rounded-md p-2 mb-4" *ngIf="this.userCategory === 'employee'">
          The added users will receive a welcome email to complete the registration to SimSkills platform.
        </div>
      </div>

    </div>
  </div>
</div>

