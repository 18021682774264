export enum ModuleEnum {
  feedback = 'feedback',
  conversations = 'conversations',
  health_dialogues = 'dialogues',
  motivation = 'motivation',
  interviews  = 'interviews',
  salesAndServices = 'sales',
  demo = 'demo',
  telefonica = 'telefonica',
  customer = 'customer'
}
