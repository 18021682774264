import { ModuleEnum } from "../utils/enums/module.enum";

export const COMPANY_MODULES = [
  ModuleEnum.feedback,
  ModuleEnum.conversations,
  ModuleEnum.motivation,
  ModuleEnum.interviews,
  ModuleEnum.health_dialogues,
  ModuleEnum.salesAndServices,
  ModuleEnum.demo,
];
