import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';


@Component({
  selector: 'login-page',
  templateUrl: './login.component.html',
  styleUrls: [],
})
export class LoginComponent {
  hide = true;

  public loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    let connected = await this.authService.isConnected();
    if (connected) {
      let userInfo = localStorage.getItem('userInfo')!;
      let parsedUser = JSON.parse(userInfo);
      let url: string = `/admin-users-table`;
      this.router.navigateByUrl(url);
    }
  }

  isValid() {
    return (
      this.loginForm.controls['username'].status === 'VALID' &&
      this.loginForm.controls['password'].status === 'VALID'
    );
  }
  navToPlatform() {
    window.open('https://simskills.io/#demo', '_blank');
  }

  login() {
    if (
      !(
        this.loginForm.controls['username'].value &&
        this.loginForm.controls['password'].value &&
        this.isValid()
      )
    ) {
      return;
    }

    let data = {
      email: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.authService
      .emailAuth(data)
      .then((user) => {
        if (user) {
          // Check if the email is verified?
          this.proceed(user);
        }
      })
      .catch((error) => {
        this.handleLoginError(error.code);
      });
  }

  handleLoginError(error: string) {
    let errorMessage: string;
    switch (error) {
      case 'auth/user-not-found':
        errorMessage = this.translate.instant('LOGIN_ERRORS.USER_NOT_FOUND');
        break;
      case 'auth/invalid-email':
        errorMessage = this.translate.instant('LOGIN_ERRORS.INVALID_EMAIL');
        break;
      case 'auth/wrong-password':
        errorMessage = this.translate.instant('LOGIN_ERRORS.WRONG_PASSWORD');
        break;
      case 'auth/email-already-in-use':
        errorMessage = this.translate.instant(
          'LOGIN_ERRORS.EMAIL_ALREADY_IN_USE'
        );
        break;
      default:
        errorMessage = this.translate.instant('LOGIN_ERRORS.DEFAULT_ERROR');
        break;
    }

    this.snackBar.open(errorMessage, '', {
      duration: 4000,
    });
  }

  proceed(user: {
    [x: string]: any;
    approved?: any;
    uid?: any;
    company?: any;
    companyLowercase?: any;
    companyId?: any;
    firstName?: any;
    lastName?: any;
    displayName?: any;
    role?: string;
  }) {
    // Check if user already exists
    if (user.approved) {
      this.firebaseService.getDocument(`/users/${user.uid}`).then((res: any) => {
        // Exisiting user
        if (res) {
          let afterLoginUrl: string = '';
          // Save userData
          const userInfo = JSON.stringify(res);
          localStorage.setItem('userInfo', userInfo);
          let parsedUser = JSON.parse(userInfo);
          if (!parsedUser.role) {
            let errorMessage: string = this.translate.instant(
              'LOGIN_ERRORS.PERMISSION_DENIED'
            );
            this.snackBar.open(errorMessage, '', {
              duration: 4000,
            });
            this.authService.logout();
          } else {
            // set las login.
            this.firebaseService.set(`/user_journey/${res.company}/${user.uid}/last_login`,  moment().format()).then();
            if (parsedUser.role === 'manager') {
              afterLoginUrl = '/manager-users-table';
            }
            if (parsedUser.role === 'admin') {
              afterLoginUrl = '/admin-users-table';
            }

            this.router.navigateByUrl(afterLoginUrl).then(() => {
              window.location.reload();
            });
          }
        }
        // New user
        else {
          console.log('user doesnt exist');
        }
      });
    } else {
      this.authService.logout();
    }
  }
}
