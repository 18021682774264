import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GenericFunctions } from '../../utils/generic-functions';

@Injectable({
  providedIn: 'root'
})
export class ApplicationValidator {

  public emailDomains(emailDomainControl: AbstractControl | FormControl): { [key: string]: any } | any {
    let emailDomainSubscription: Subscription;
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || !emailDomainControl?.value) {
        // @ts-ignore
        return;
      }
      if (emailDomainSubscription && !emailDomainSubscription.closed) {
        emailDomainSubscription.unsubscribe();
      }
      emailDomainSubscription = emailDomainControl.valueChanges.subscribe((domain) => {
        emailDomainSubscription.unsubscribe();
        control.updateValueAndValidity();
      });
      const emails = control.value.replace(/(?:\r\n|\r|\n|;)/g, ',').split(',')
      const invalidEmails = emails.filter((email: string) => !email.trim().endsWith(`@${emailDomainControl.value}`))
        .filter((email: string) => email)
      return !invalidEmails.length ? null : {emailDomains: {invalidEmails, domain: emailDomainControl.value}};
    };
  }

  public emailLimit(limit: number): { [key: string]: any } | any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || GenericFunctions.isNullOrUndefined(limit)) {
        // @ts-ignore
        return;
      }
      const emails = control.value.replace(/(?:\r\n|\r|\n|;)/g, ',').split(',');
      return emails.length <= limit ? null : {emailLimit: true};
    };
  }

  public companyName(): { [key: string]: any } | any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const companyRegex = RegExp("^[a-z A-Z-]*$");
      return companyRegex.test(control.value) ? null : {companyName: {message: 'INVALID_COMPANY'}};
    };
  }
}
