import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { User, UserJourney, UserSimulations } from '../models/simskillsModels';
import { Journey } from '../dialogs/user-detail-dialog/user-detail-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  user: any;

  constructor(private firebaseService: FirebaseService) {}

  public isUserAuthorized(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/authorized_users/' + uid).then(
        (res: any) => {
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public async changeAuthorized(uid: string) {
    const authorized = await this.isUserAuthorized(uid);
    // Autorize the user
    this.firebaseService.updateDocument('/authorized_users', uid, {
      approved: !authorized.approved,
    });
  }

  public async addUser(companyName: string,  user: any): Promise<any> {
    return this.firebaseService.addDocument(`/added_users/${companyName}/`, user).then(res => {
      return res;
    });
  }

  public async getAmountAvatarSimulations(uid: string) {
    const userSimulations = await this.getUserSimulations(uid);
    if (userSimulations) {
      let objectUserSimulations: UserSimulations[] =
        Object.values(userSimulations);
      let avatarSimulations = 0;
      avatarSimulations = objectUserSimulations.reduce((total, obj) => {
        const simulations = Object.values(obj);

        simulations.forEach((attempts: any) => {
          total += Object.values(attempts).filter(
            (attempt: any) => attempt.completed
          ).length;
        });
        return total;
      }, 0);
      return avatarSimulations;
    }
    return 0;
  }

  public async getAmountAvatarSimulationsByCompany(companyId: string) {
    let totalAmount = 0;
    const users = await this.getUsersByCompany(companyId);
    for (const user of users) {
      totalAmount += await this.getAmountAvatarSimulations(user.uid);
    }
    return totalAmount;
  }

  public async getUsersByCompany(companyId: string) {
    const users = await this.getUsers();
    const usersInCompany: any[] = [];

    Object.values(users).forEach((user: any) => {
      if (user.companyId === companyId) {
        usersInCompany.push(user);
      }
    });

    return usersInCompany;
  }

  public async getAmountP2PSimulations(uid: string) {
    const scheduledSimulations = await this.getUserScheduledSimulations(uid);
    if (scheduledSimulations) {
      let objectUserSimulations: any = Object.values(scheduledSimulations);
      return objectUserSimulations.filter((obj: any) => obj.status === 'done')
        .length;
    }
  }

  public async getIncommingScheduledSimulations(uid: string) {
    const scheduledSimulations = await this.getUserScheduledSimulations(uid);
    if (scheduledSimulations) {
      let objectUserSimulations: any;
      objectUserSimulations = Object.values(scheduledSimulations);
      const currentDate = new Date();
      // TODO: Compare time
      const incommingScheduledSimulations = objectUserSimulations.filter(
        (obj: any) => new Date(obj.p2p_date) > currentDate
      );
      if (incommingScheduledSimulations) {
        return incommingScheduledSimulations;
      }
    }
    return [];
  }

  public getUserJourney(company: string, uid: string) {
    const companyLowerCase = company.toLowerCase();
    return new Promise<UserJourney>((resolve, reject) => {
      this.firebaseService
        .getDocument('/user_journey/' + companyLowerCase + '/' + uid)
        .then(
          (res: UserJourney) => {
            let userJourney: UserJourney = res as UserJourney;
            resolve(userJourney);
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  public async getCompanyJourney(company: string, department?: string): Promise<any> {
    const result: any = {
      registerUsers: 0,
      notRegisterUsers: 0,
      totalUsers: 0,
    }
    const companyLowerCase = company.replaceAll('.', '').toLowerCase();
    if(!company) {
      return {};
    }

    let journeys, users;
    if (department) [journeys, users] = await Promise.all([this.firebaseService.getDocumentsListOnce('/user_journey/' + companyLowerCase, {orderByChild: 'department', equalTo: department}), this.getCompanyAddedUsers(company, department)]);
    else [journeys, users] = await Promise.all([this.firebaseService.getDocumentsListOnce('/user_journey/' + companyLowerCase), this.getCompanyAddedUsers(company)]);

    result.registerUsers = journeys.length;
    result.notRegisterUsers = users.length;
    result.totalUsers = result.registerUsers + result.notRegisterUsers;


    let journey = {};
    for (const journeyElement of journeys) {
      journey[journeyElement.id] = journeyElement
    }
    if (!journey) journey = {};
    for (var key in users) { users[key].active = false; journey[key] = users[key]; }
    for (var key in journey) journey[key].company = company;

    result.journey = journey;
    return result;
  }

  public getCompanyAddedUsers(company: string, department?: string) {
    const companyLowerCase = company.replaceAll('.', '').toLowerCase();
    return new Promise<Journey>((resolve, reject) => {
      this.firebaseService.getDocumentsListOnce('/added_users/' + companyLowerCase, {orderByChild: 'department', equalTo: department }).then((res: any) => { resolve(res); }, (err: any) => { reject(err); });
    });
  }

  public getJourney() {
    return new Promise<Journey>((resolve, reject) => {
      this.firebaseService.getDocument('/user_journey').then((res: Journey) => {
        let journey: Journey = res as Journey;
        resolve(journey);
      },
      (err: any) => {
        reject(err);
      });
    });
  }

  public getUserSimulations(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/user_simulations/' + uid).then(
        (res: UserSimulations) => {
          let userSimulations: UserSimulations = res as UserSimulations;
          resolve(userSimulations);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getUserAssessments(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/user_assessments/' + uid).then(
        (res: any) => {
          //  let userAssessments: user = res as UserSimulations;
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getUserScheduledSimulations(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService
        .getDocument('/user_scheduled_simulations/' + uid)
        .then(
          (res: any) => {
            let userScheduledSimulations: any =
              res as any;
            resolve(userScheduledSimulations);
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  public getUsers() {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/users/').then(
        (res: any) => {
          //let userData: User = res as User;
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getUser(uid: string) {
    return new Promise<User>((resolve, reject) => {
      this.firebaseService.getDocument('/users/' + uid).then(
        (res: User) => {
          let userData: User = res as User;
          resolve(userData);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public async getUserCompanyId(uid: string): Promise<string> {
    return await this.firebaseService.getDocument(`/users/${uid}/companyId`);
  }

  public getCompanies() {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocumentsListOnce('/companies', { orderByChild: 'name_lowercase' }).then((res: any) => {
          let companies: any = res as any;
          companies = companies.filter(c => c.active).sort((a, b) => {
            if (a.name_lowercase < b.name_lowercase) return -1;
            if (a.name_lowercase > b.name_lowercase) return 1;
            return 0;
          });
          resolve(companies);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getCompany(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/companies' + uid).then(
        (res: any) => {
          let company: any = res as any;
          resolve(company);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public getAssessments() {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/assessments').then((res: any) => {
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

}
