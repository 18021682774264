import { UserGradeEnum } from '../../../../utils/enums/user-grade.enum';

export const SIMULATION_PROGRESS_SETTINGS = {
  avatar: [
    {
      userGrade: UserGradeEnum.beginner,
      config: [
        {
          level: 1,
          value: 2,
        },
        {
          level: 2,
          value: 2,
        },
        {
          level: 3,
          value: 2,
        },
      ]
    },
    {
      userGrade: UserGradeEnum.medium,
      config: [
        {
          level: 1,
          value: 1,
        },
        {
          level: 2,
          value: 2,
        },
        {
          level: 3,
          value: 3,
        },
      ]
    },
    {
      userGrade: UserGradeEnum.advanced,
      config: [
        {
          level: 1,
          value: 1,
        },
        {
          level: 2,
          value: 1,
        },
        {
          level: 3,
          value: 4,
        },
      ]
    }
  ],
  peer: [
    {
      userGrade: UserGradeEnum.beginner,
      value: 3
    },
    {
      userGrade: UserGradeEnum.medium,
      value: 3
    },
    {
      userGrade: UserGradeEnum.advanced,
      value: 3
    },
  ],
  score: 70
};

export const SIMULATION_DEMO_PROGRESS_SETTINGS = {
  avatar: [
    {
      userGrade: UserGradeEnum.beginner,
      config: [
        {
          level: 1,
          value: 0,
        },
        {
          level: 2,
          value: 0,
        },
        {
          level: 3,
          value: 0,
        },
      ]
    }
  ],
  score: 0
};


