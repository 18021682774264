import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  transform(values: any[], filterValue: any, filterProperty: string): any {
    if (!values) {
      return;
    }
    return values.find(x => x[filterProperty] === filterValue);
  }

}
