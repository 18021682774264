import { Injectable } from '@angular/core';
import { UserJourney } from '../models/simskillsModels';
import { FirebaseService } from './firebase.service';
import {
  SIMULATION_DEMO_PROGRESS_SETTINGS,
  SIMULATION_PROGRESS_SETTINGS
} from '../modules/admin/user/settings/simulation-progress.settings';
import { GenericFunctions } from '../utils/generic-functions';
import { CompaniesService } from './companies.service';
import { ModuleEnum } from "../utils/enums/module.enum";
import { UserGradeEnum } from "../utils/enums/user-grade.enum";

@Injectable({
  providedIn: 'root',
})
export class UserJourneysService {
  constructor(
    private firebaseService: FirebaseService,
    private companiesService: CompaniesService
  ) {
  }

  public getUserJourneys() {
    return this.firebaseService.getDocument('/user_journey/');
  }

  public getCompanyUserJourneys(companyName: string) {
    return this.firebaseService.getDocument('/user_journey/' + companyName);
  }

  public getUserJourney(companyName: string, userId: string) {
    return this.firebaseService.getDocument(
      '/user_journey/' + companyName + '/' + userId
    );
  }

  public async removeUserJourney(companyName: string, userId: string) {
    return await this.firebaseService.deleteDocument(
      '/user_journey/' + companyName,
      userId
    );
  }

  public async editUserJourney(
    companyName: string,
    userId: string,
    updatedData: Partial<UserJourney[string][string]>
  ) {
    return await this.firebaseService.updateDocument(
      '/user_journey/' + companyName,
      userId,
      updatedData
    );
  }

  public async getCompanySimulationProgressSettings(companyCode: string): Promise<{
    [key: string]: any;
    score: number
  }> {
    const simulationsConfig = SIMULATION_PROGRESS_SETTINGS;
    const auxSimulationsConfig: any = await this.firebaseService.getDocument(`companies/${companyCode}/settings/simulations`);
    return {
      avatar: auxSimulationsConfig?.avatar || simulationsConfig.avatar,
      peer: auxSimulationsConfig?.peer || simulationsConfig.peer,
      score: !GenericFunctions.isNullOrUndefined(auxSimulationsConfig?.score) ? auxSimulationsConfig?.score : simulationsConfig.score
    };
  }

  public async getCompletedAvatarSimulations(module: ModuleEnum | string, user: string, minScoreConfig: number): Promise<any> {
    let [userSimulations, avatarSimulations] = await Promise.all([
      this.firebaseService.getDocumentsListOnce(`user_simulations/${module}/${user}`),
      this.firebaseService.getDocumentsListOnce(`simulations/${module}`),
    ]);
    return {
      userSimulations: userSimulations,
      completedSimulations: userSimulations
        .filter(simulation => Object.keys(simulation).some(attempt => simulation[attempt].scoring >= minScoreConfig))
        .map(simulation => ({...simulation, level: avatarSimulations.find(x => x.id === simulation.id)?.level}))
    };
  }

  public async getSimulationProgressSetting(company: string, user: string): Promise<{
    [key: string]: any;
    score: number
  }> {
    if (await this.isDemo(company, user)) {
      return SIMULATION_DEMO_PROGRESS_SETTINGS;
    }
    const simulationsConfig = SIMULATION_PROGRESS_SETTINGS;
    const companyPayload = await this.companiesService.getCompanyByNameLowerCase(company);
    const auxSimulationsConfig: any = await this.firebaseService.getDocument(`companies/${companyPayload?.code}/settings/simulations`);
    return {
      avatar: auxSimulationsConfig?.avatar || simulationsConfig.avatar,
      peer: auxSimulationsConfig?.peer || simulationsConfig.peer,
      score: !GenericFunctions.isNullOrUndefined(auxSimulationsConfig?.score) ? auxSimulationsConfig?.score : simulationsConfig.score
    };
  }

  public async isDemo(company: string, user: string): Promise<boolean> {
    const isDemo = await this.firebaseService.getDocument(`user_journey/${company}/${user}/demo`);
    return !!isDemo;
  }

  public async getUserGrade(company: string, user: string, module: ModuleEnum): Promise<UserGradeEnum> {
    if (await this.isDemo(company, user)) {
      return UserGradeEnum.beginner;
    }
    return await this.firebaseService.getDocument(`user_journey/${company}/${user}/modules/${module}/grade`);
  }
}
