import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count'
})
export class CountPipe implements PipeTransform {

  transform(values: any[], filterValue: any, filterProperty: string): any {
    if (!values) {
      return;
    }
    return values.filter(x => x[filterProperty] === filterValue)?.length || 0;
  }

}
